import { get } from "lodash";
import axios from "axios";

const findWl = () => {
  const wlConfig = JSON.parse(localStorage.getItem("_wlConfig"));
  return wlConfig ? wlConfig : {};
};

const loadConfig = async (cb) => {
  const isLocalhost =
    window.location.host.indexOf("localhost") > -1 ||
    // window.location.host.indexOf("evil") > -1 ||
    window.location.host.indexOf("192") > -1;
  const baseUrl = "https://abordo-cms.dex.company/whitelabels/domain";
  const params = isLocalhost && {
    key: "Dt2Adep6m73bWHeJ",
    domain: 'http://passaromarron-wl-2.dex.company',
    //  domain: 'http://itapemirim-wl.dex.company'
    // domain: 'https://expressofloriano.dex.company',
    // domain: 'https://mobifacil-wl.dex.company',
    // domain: 'https://app.expressomaringa.com.br'
    // domain: 'https://penha-wl.dex.company'
    // domain: "https://www.reunidasmobi.com.br",
    // domain: 'http://app.expressoluxo.com.br'
    // domain: 'https://www.expressoitamarati.com.br'
    // domain: "https://mybus-wl.dex.company",
    // domain: 'http://www.viajetranspiaui.com.br'
    // domain: 'https://grupojb-wl.dex.company'
    // domain: 'abordoModelo'
  };
  const { data } = await axios.get(baseUrl, { params });
  await localStorage.setItem("_wlConfig", JSON.stringify(data));
  localStorage.setItem("_wlConfigTime", new Date().getTime());
  cb({ data, status: true });
};

const getConfig = (queryArray) => {
  const wl = findWl();
  return queryArray.map((queryObj) => {
    try {
      const res = get(wl, queryObj);
      if (!res)
        throw new Error(
          `Config not found for ${queryObj}, in ${JSON.stringify(queryArray)}`
        );
      return res;
    } catch ({ message }) {
      console.log(message);
      return {};
    }
  });
};

export { loadConfig, getConfig, findWl };
