import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from '@sentry/browser';
import swConfig from "./swConfig";

// require("dotenv").config();

Sentry.init({
    dsn: "https://fc7569fc62484654b27012c9d59eb37f@o412622.ingest.sentry.io/4504809745547264",
    environment: window.getEnv()
});


ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorker.unregister(); //register(swConfig);
