import React, { useState, useEffect } from "react";
import "./app.css";

// USEFUL MODULES
import "./index.css";

import dynamicComponent from "./utils/dynamicImport";
import { loadConfig, wlContentProvider } from './whitelabel';
import { ThemeProvider } from 'styled-components';

const isLocalhost = window.location.host.indexOf('localhost') > -1;

const App = () => {

  const [wlTheme, setWlTheme] = useState();
  const [wlContent, setWlContent] = useState();
  const [wlConfig, setWlConfig] = useState();

  const Routes = dynamicComponent(() => import("./routes"));

  useEffect(() => {

    const _wlConfigTime = localStorage.getItem('_wlConfigTime');
    const _wlConfig = localStorage.getItem('_wlConfig');

    const lastUpdate = _wlConfigTime && Number(_wlConfigTime);
    const oneDay = 1000 * 60 * 60 * 24;
    const now = new Date().getTime();

    if (isLocalhost || !lastUpdate || (now > lastUpdate + oneDay)) {
      loadConfig(({ status, data }) => {
        const { content, styles } = data;
        setWlContent(content);
        setWlTheme(styles);
      });
    } else {
      const { content, styles } = JSON.parse(_wlConfig);
      setWlContent(content);
      setWlTheme(styles);
    }
  }, [])

  useEffect(() => {
    wlTheme && wlContent && setWlConfig(true);
  }, [wlTheme, wlContent])

  return (
    wlConfig ?
      <div style={{ height: "-webkit-fill-available" }}>
        <ThemeProvider theme={wlTheme} >
          {/* <WlContentProvider data={wlContent}> */}
          <Routes />
          {/* </WlContentProvider> */}
        </ThemeProvider>
      </div >
      : <></>
  );
}


export default App