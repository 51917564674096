import React from "react";

const dynamicComponent = importFunc => {
    return class App extends React.Component {
        state = {
            component: null
        };
        componentDidMount = () => {
            importFunc().then(comp => {
                this.setState({
                    component: comp.default
                });
            });
        };
        render() {
            const { component: C } = this.state;
            return C ? <C {...this.props} /> : <></>;
        }
    };
};

export default dynamicComponent;
